import React from "react";
import { ResponsiveValue } from "styled-system";

import { Flex, Text, NewList, NewListItem } from "Atoms";
import { SelectedBundleProductItem } from "Types";
import { OnlineAddonsList } from "Organisms";
import { getSelectedModificationAddonPriceFromBackend, mathSign } from "../admin/components/poses/pos/utils/priceUtils";
import { CustomerModificationsList } from "../customer/pages/orderStatus/components";

type Props = {
    selectedBundleItems: SelectedBundleProductItem[] | null | undefined;
    listStyle?: ResponsiveValue<string | undefined>;
};

export const OnlineBundleItemsList: React.FC<Props> = ({ selectedBundleItems, listStyle = "none" }) => {
    if (!selectedBundleItems) return null;

    return (
        <NewList stretch={2} listStyle={listStyle}>
            {selectedBundleItems.map(({ refProductId, modifications, name, addons }, idx) => {
                const modAddonPrice = modifications && getSelectedModificationAddonPriceFromBackend(modifications);
                const sign = mathSign(modAddonPrice);

                return (
                    <NewListItem
                        listStyle={listStyle}
                        key={`${refProductId}_${idx}`}
                        display={listStyle === "none" ? "flex" : undefined}
                        flexDirection="column"
                        alignItems="flex-start"
                    >
                        <Flex justifyContent="space-between" width="100%">
                            <Text color="gray.600" m="0">
                                {name}
                            </Text>
                            {!!modAddonPrice && modAddonPrice !== 0 && (
                                <Text as="span" color="gray.600">
                                    ({sign} {modAddonPrice} kr)
                                </Text>
                            )}
                        </Flex>
                        {addons.length > 0 && <OnlineAddonsList addons={addons} px={3} quantityIndicator="x" />}
                        {modifications && (
                            <CustomerModificationsList
                                modifications={{ sizes: modifications.sizes, flavours: modifications.flavours }}
                                productPrice={0}
                            />
                        )}
                    </NewListItem>
                );
            })}
        </NewList>
    );
};
